import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Wusstest du, dass es über 100 verschiedene HTML-Elemente gibt? Einige davon
wie `}<InlineCode mdxType="InlineCode">{`<`}{`h1`}{`>`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`<`}{`p`}{`>`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{`
sind uns allen geläufig. Doch viele Elemente fristen ein Nischendasein. Manche Web-Entwickler:innen
wissen nicht mal von ihrer Existenz.`}</p>
    <p>{`Aus diesem Grund möchte ich in diesem Artikel drei unterschätzte HTML-Elemente vor den Vorhang
holen: `}<InlineCode mdxType="InlineCode">{`<`}{`time`}{`>`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`<`}{`picture`}{`>`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFO2dQRBOI//8QAGxABAAICAwAAAAAAAAAAAAAAAgEDAAQSEzL/2gAIAQEAAQUCoiWnitrE69s8n5A7c//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB8QAAEDAwUAAAAAAAAAAAAAAAEAAhEQEhMhMUFRYf/aAAgBAQAGPwLI7WNqQZnxBnCJ6Vxcv//EABoQAQACAwEAAAAAAAAAAAAAAAEAETFBUSH/2gAIAQEAAT8hve9EyKzqEmFLSBtpWHkSE0uKH0Xyf//aAAwDAQACAAMAAAAQlz//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBYVFxgf/aAAgBAQABPxDjSF+3l9EuplVt8jYIQLBYH+E0PXsigUpjtocXEvDY7cD57n//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Tischkalender, der den Monat Jänner zeigt. Auf dem Kalender liegt eine Brille.",
          "title": "Ein Tischkalender, der den Monat Jänner zeigt. Auf dem Kalender liegt eine Brille.",
          "src": "/static/4688707652f1c2add3e575badda8d812/e5166/pexels-olya-kobruseva-calendar.jpg",
          "srcSet": ["/static/4688707652f1c2add3e575badda8d812/f93b5/pexels-olya-kobruseva-calendar.jpg 300w", "/static/4688707652f1c2add3e575badda8d812/b4294/pexels-olya-kobruseva-calendar.jpg 600w", "/static/4688707652f1c2add3e575badda8d812/e5166/pexels-olya-kobruseva-calendar.jpg 1200w", "/static/4688707652f1c2add3e575badda8d812/b17f8/pexels-olya-kobruseva-calendar.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Olya Kobruseva / pexels.com`}</em></p>
    <h2>{`Das time-Element: Maschinenlesbare Datum- und Zeitangaben`}</h2>
    <p>{`Das `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/HTML/Element/time"
      }}>{`HTML-Element`}</a>{` `}<InlineCode mdxType="InlineCode">{`<`}{`time`}{`>`}</InlineCode>{`
repräsentiert einen konkreten Zeitpunkt, Datum oder Zeitraum. Zusammen mit dem Attribut `}<InlineCode mdxType="InlineCode">{`datetime`}</InlineCode>{`
wird die Zeitangabe maschinenlesbar. Wozu das gut ist? Hier ein Beispiel:`}</p>
    <iframe scrolling="no" title="Code-Beispiel für die Verwendung des time-Elements" src="https://codepen.io/alexlehner86/embed/GROjOjZ?default-tab=result" loading="lazy" allowtransparency="true">
    Öffne das Code-Beispiel <a href="https://codepen.io/alexlehner86/pen/GROjOjZ" lang="en">
    time element German</a> von Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    auf <a href="https://codepen.io" lang="en">CodePen</a>.
    </iframe>
    <p>{`Das Beispiel enthält drei Termine, deren Datum jeweils im Stil eines Abreißkalenders dargestellt wird. Vielleicht
denkst du jetzt: Oida, das erklärt sich eh von selbst! Zusammen mit der Überschrift „Termine 2022“ ist doch klar,
welches Datum gemeint ist.`}</p>
    <p>{`Sehende werden wohl tatsächlich kein Problem mit dieser Darstellung des Datums haben. Anders die Situation für
blinde oder sehbeeinträchtigte Nutzer:innen. Wenn der Screenreader "mär vier" oder "jul acht" vorliest, wird
das verwirrend sein. Das `}<InlineCode mdxType="InlineCode">{`<`}{`time`}{`>`}</InlineCode>{` Element sorgt hier für Klarheit.`}</p>
    <p>{`Leider unterstützen noch nicht alle gängigen Screenreader das Element.
`}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/VoiceOver"
      }}><span lang="en">{`VoiceOver`}</span></a>{` liest das im `}<InlineCode mdxType="InlineCode">{`datetime`}</InlineCode>{`
Attribut definierte Datum korrekt vor (getestet mit einem iPhone 8, iOS 15, Safari). Mit den Screenreadern NVDA und TalkBack
funktioniert das leider derzeit nicht.`}</p>
    <h2>{`Das picture-Element: Bilder in der passenden Größe laden`}</h2>
    <p>{`Das `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/HTML/Element/picture"
      }}>{`HTML-Element`}</a>{` `}<InlineCode mdxType="InlineCode">{`<`}{`picture`}{`>`}</InlineCode>{`
ist ein Container, der zur Angabe mehrerer Quellen für ein Bild dient. Es muss ein `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{`
Element und kann mehrere `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` Elemente enthalten. Damit kannst du das Bild für verschiedene
Bildschirmauflösungen und Browser optimieren. Schauen wir uns ein Beispiel an:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<picture>
    <source srcset="cat_lg.avif" type="image/avif" media="(min-width: 60rem)">
    <source srcset="cat_md.avif" type="image/avif">
    <source srcset="cat_lg.webp" type="image/webp" media="(min-width: 60rem)">
    <source srcset="cat_md.webp" type="image/webp">
    <source srcset="cat_lg.jpg" media="(min-width: 60rem)">
    <img src="cat_md.jpg" alt="An adorable cat" loading="lazy" decoding="async">
</picture>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das Attribut `}<InlineCode mdxType="InlineCode">{`type`}</InlineCode>{` erlaubt die Angabe des MIME-Typs der im Attribut `}<InlineCode mdxType="InlineCode">{`srcset`}</InlineCode>{`
angegebenen Ressource. Das erste `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` Element im Beispiel oben verweist auf ein Bild im
neuen `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/AV1_Image_File_Format"
      }}>{`AVIF-Format`}</a>{`. Wenn der Browser AVIF-Bilder wiedergeben kann,
wählt er diese Bilddatei. Andernfalls geht es weiter zum nächsten `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` Element.`}</p>
    <p>{`Auf diese Weise können moderne Browser, die das AVIF- oder `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/WebP"
      }}>{`WebP-Format`}</a>{` unterstützen,
ein stark komprimiertes Bild in hoher Qualität laden. Ältere Browser können hingegen auf die JPG-Version des Bildes zurückgreifen.`}</p>
    <p>{`Das Attribut `}<InlineCode mdxType="InlineCode">{`media`}</InlineCode>{` erlaubt die Angabe einer Media-Query, die der Browser auswertet. Im Beispiel sagen
wir dem Browser, das größere Bild zu verwenden, wenn die Bildschirmbreite mindestens 60 rem beträgt. Wenn eine Media-Query
nicht zutrifft, geht es ebenfalls weiter zum nächsten `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` Element.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFsrZJbBAf/xAAdEAABAwUBAAAAAAAAAAAAAAABAhEhAAMSEzEz/9oACAEBAAEFAtEr9AXFzuD0mB//xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAERIVEiMWH/2gAIAQEABj8CTOaHoc6So2z/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUGR4f/aAAgBAQABPyFBeLGdMEoVfUQB2C74ppTUVU8SYfDrP//aAAwDAQACAAMAAAAQFy//xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8QxIn/xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8QrH//xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhUTFBkf/aAAgBAQABPxCWWhCuw3Fx7JcZwACVmpgNeQzmWL5Azu9ABZ83/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine schlafende Katze mit schwarz-grauem Fell.",
          "title": "Eine schlafende Katze mit schwarz-grauem Fell.",
          "src": "/static/5c6b65462e663749620e7bc2f45eef32/e5166/pexels-ihsan-adityawarman-sleeping-cat.jpg",
          "srcSet": ["/static/5c6b65462e663749620e7bc2f45eef32/f93b5/pexels-ihsan-adityawarman-sleeping-cat.jpg 300w", "/static/5c6b65462e663749620e7bc2f45eef32/b4294/pexels-ihsan-adityawarman-sleeping-cat.jpg 600w", "/static/5c6b65462e663749620e7bc2f45eef32/e5166/pexels-ihsan-adityawarman-sleeping-cat.jpg 1200w", "/static/5c6b65462e663749620e7bc2f45eef32/b17f8/pexels-ihsan-adityawarman-sleeping-cat.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Ihsan Adityawarman / pexels.com`}</em></p>
    <p>{`Eine weitere Möglichkeit zur Bildoptimierung bietet das Attribut `}<InlineCode mdxType="InlineCode">{`srcset`}</InlineCode>{`. Dieses kannst du auch
direkt mit dem `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{` Element verwenden, wie das süße Katzenfoto oben zeigt. Dessen
HTML-Code sieht ungefähr so aus:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<img
    srcset="cat-480w.jpg 480w, cat-800w.jpg 800w"
    sizes="(max-width: 600px) 480px, 800px"
    src="cat-800w.jpg"
    alt="Eine schlafende Katze mit schwarz-grauem Fell."
>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`srcset`}</InlineCode>{` Attribut definiert eine Reihe von Bildern, aus denen der Browser wählen kann,
sowie die Größe der Bilder. Das `}<InlineCode mdxType="InlineCode">{`sizes`}</InlineCode>{` Attribut definiert eine Reihe von Bedingungen
(z.B. Bildschirmbreite) und gibt an, welche Bildgröße der Browser wählen sollte, wenn eine bestimmte Media-Query zutrifft.`}</p>
    <p>{`Wenn ihr noch tiefer in das Thema einsteigen wollt, kann ich folgende Artikel empfehlen:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images"
        }}><span lang="en">{`Responsive Images (MDN Web Docs)`}</span></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.dev/learn/design/picture-element/"
        }}><span lang="en">{`The picture element (web.dev)`}</span></a></li>
    </ul>
    <h2>{`Schlüssel-Wert-Paare mit dl, dt und dd auszeichnen`}</h2>
    <p>{`Listen zählen zu den grundlegenden Bausteinen in HTML. Jede:r Web-Entwickler:in kennt die
HTML-Elemente `}<InlineCode mdxType="InlineCode">{`<`}{`ul`}{`>`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`<`}{`ol`}{`>`}</InlineCode>{` für
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/HTML/Element/ul"
      }}>{`ungeordnete`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/HTML/Element/ol"
      }}>{`geordnete Listen`}</a>{`.
Doch wenige sind mit ihrem nahen Verwandten `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{` vertraut.`}</p>
    <p>{`Diese sogenannte `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/HTML/Element/dl"
      }}><span lang="en">{`Description List`}</span></a>{`
umfasst eine Liste an Begriffen und Beschreibungen, die mit den HTML-Elementen `}<InlineCode mdxType="InlineCode">{`<`}{`dt`}{`>`}</InlineCode>{`
und `}<InlineCode mdxType="InlineCode">{`<`}{`dd`}{`>`}</InlineCode>{` definiert werden. Üblicherweise wird es zur Anzeige eines Glossars oder von
Metadaten (also einer Liste von Schlüssel-Wert-Paaren) genutzt.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dl>
    <dt>Kontoinhaber</dt>
    <dd>Martina Musterfrau</dd>
    <dt>Bankinstitut</dt>
    <dd>Musterbank</dd>
    <dt>IBAN</dt>
    <dd>AT12 3456 7891 2345 6789</dd>
</dl>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Was macht das Element in meinen Augen so großartig? Erraten! Es macht den Inhalt barrierefrei für Screenreader
zugänglich. Doch auch Web-Entwickler:innen profitieren davon. Stell dir das Beispiel oben als eine
Abfolge von `}<InlineCode mdxType="InlineCode">{`<`}{`div`}{`>`}</InlineCode>{` Elementen vor und überlege, in welchen Code du dich schneller
einlesen und zurechtfinden könntest. `}<strong parentName="p">{`Semantisches HTML macht Code lesbarer!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      